var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        { staticClass: "p-0 m-0 form-card" },
        [
          _c("CCardHeader", [
            _c("label", { staticClass: "header-title" }, [_vm._v("機器一覧")]),
            _vm.$store.state.user.role == "ADMINISTRATOR" ||
            _vm.$store.state.user.role == "REGISTRATOR"
              ? _c(
                  "div",
                  { staticClass: "card-header-actions" },
                  [
                    _c(
                      "CButton",
                      {
                        staticClass: "mr-3",
                        attrs: { color: "info", size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.sampleDownload()
                          }
                        }
                      },
                      [_vm._v("テンプレートダウンロード")]
                    ),
                    _c(
                      "CButton",
                      {
                        attrs: { color: "primary", size: "sm" },
                        on: {
                          click: function($event) {
                            _vm.isCollapsed = !_vm.isCollapsed
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.isCollapsed ? "登録" : "一覧") + "モードへ"
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "CCardBody",
            { staticClass: "p-0 m-0" },
            [
              _c(
                "CCollapse",
                { attrs: { show: !_vm.isCollapsed, duration: 200 } },
                [
                  _c(
                    "CRow",
                    { staticClass: "px-0 mx-0" },
                    [
                      _c(
                        "CCol",
                        { staticClass: "col-6" },
                        [
                          _c("CSelect", {
                            attrs: {
                              label: "機器登録",
                              autocomplete: "device kind",
                              value: _vm.formValue.deviceKind,
                              options: _vm.deviceOptions
                            },
                            on: {
                              "update:value": function($event) {
                                _vm.formValue.deviceKind = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { staticClass: "col-4" },
                        [
                          _c("CInputFile", {
                            attrs: {
                              label: "ファイル",
                              autocomplete: "file",
                              inline: ""
                            },
                            on: {
                              change: function($event) {
                                _vm.formValue.file = $event[0]
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CRow",
                    { staticClass: "px-0 mx-0" },
                    [
                      _c(
                        "CCol",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "mb-2",
                              attrs: { color: "danger", size: "sm" },
                              on: { click: _vm.upload }
                            },
                            [_vm._v("登録")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        { staticClass: "p-0 m-0 grid-card" },
        [
          _c("Device", {
            ref: "device",
            attrs: {
              "show-lost-discard": _vm.showLostDiscard,
              "show-download": true,
              "is-device-list": true,
              "is-collapsed-nested": _vm.isCollapsed,
              "open-height": "58vh"
            },
            on: { lost: _vm.lost, discard: _vm.discard }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }